import toastMessage from "../toastMessage";
import { getStorage } from "../storage";
import axios from "axios";
import language from "../../language";

const API_URL = process.env.REACT_APP_PSRP_BASE_API;

export const fetchUserGuide = async (lng = "english") => {
  try {
    const user = await getStorage();

    let headers = {
      Authorization: `Bearer ${user.token}`,
    };

    if (!user.token) return;

    let route = "";

    if (user.role !== "user") {
      route = "userGuideAdmin";
    } else {
      route = "userGuideApplicant";
    }

    const options = {
      method: "GET",
      url: `${API_URL}/${route}`,
      headers,
    };

    const queryRes = await axios(options);

    return queryRes.data || {};
  } catch (error) {
    console.log("fetch user guide error: ", error);
    toastMessage("error", language[lng].unable_fetch_user_guide);
  }
};
