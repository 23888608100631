import React from "react";
import { connect } from "react-redux";
import { Checkbox, Input, Select } from "../../Input";
import { Button } from "../../Button";
import language from "../../../language";
import axios from "axios";
import toastMessage from "../../../utils/toastMessage";
import { common_input_types } from "../../../constants/document";
import { getStorage } from "../../../utils/storage";
import MultipleChoice from "./DynamicForm/MultipleChoice";
import MultipleAnswer from "./DynamicForm/MultipleAnswer";
import urlValidation from "../../../utils/validateURL";
import IntegrationForm from "./DynamicForm/IntegrationForm";

class NewQuestionnaire extends React.Component {
  state = {
    error: {},
    questionText: "",
    answerType: "",
    answerOptions: [],
    multiAnswer: [],
    responseKey: "data",
    apiMethod: "GET",
    apiParams: "",
  };

  componentDidMount() {
    let { dataIndex, serviceData } = this.props;

    if (dataIndex !== undefined) {
      const { singleAnswer, answerType, multiAnswer } =
        serviceData?.questionnaire[dataIndex] || {};

      let _answerType = {};
      common_input_types(this.props.defaultLanguage).forEach((el) => {
        if (el.value === answerType || el.type === answerType) {
          _answerType = el;
        }
      });

      this.setState({
        ...(serviceData?.questionnaire[dataIndex] || {}),
        answerType: _answerType,
        answerOptions: multiAnswer,
        dataIndex,
        ...singleAnswer,
      });
    }
  }

  onChangeText(field, e) {
    let { error } = this.state;
    delete error[field];

    this.setState({
      [field]: e.target ? e.target.value : e,
      error,
    });

    if (e.integrationSettings) {
      this.setState({ ...e.integrationSettings });
    }
  }

  handleMultipleChoiceChanges({
    arrayName = "answerOptions",
    action,
    index,
    item,
  }) {
    console.log("new item added *****", item);
    let answerOptions = this.state[arrayName];

    if (!item) return;

    if (action === "typing") {
      answerOptions[index] = item;
    }

    if (action === "add") {
      answerOptions.push(item);
    }

    if (action === "remove") {
      answerOptions.splice(index, 1);
    }

    this.setState({ [arrayName]: answerOptions });
  }

  handleCheckbox(field) {
    this.setState({
      [field]: !this.state[field],
    });
  }

  validateForm = async () => {
    let { answerType, error, questionText, answerOptions } = this.state;

    if (!answerType) {
      error.answerType =
        language[this.props.defaultLanguage].input_type_required;
    }

    if (questionText === "") {
      error.questionText =
        language[this.props.defaultLanguage].question_required;
    }

    this.validateAnswerOptions();

    this.setState({ error });
  };

  validateAnswerOptions() {
    const { answerOptions, error, answerType = {} } = this.state;

    answerOptions.forEach((el, index) => {
      this.validateIntegrationForm({
        ...el,
        index,
        isMultipleOption: true,
      });
    });

    if (
      answerType.value === "integration" ||
      answerType.type === "integration"
    ) {
      this.validateIntegrationForm({
        name: this.state.name,
        apiUrl: this.state.apiUrl,
        dropdownFieldName: this.state.dropdownFieldName,
        dropdownFieldValue: this.state.dropdownFieldValue,
        responseKey: this.state.responseKey,
        apiMethod: this.state.apiMethod,
        option: answerType,
        apiParams: this.state.apiParams,
      });
    }

    console.log("Error returned are ******", error);

    this.setState({ error });
  }

  validateIntegrationForm(params) {
    const { error } = this.state;
    console.log("params ", params);

    const {
      name,
      apiUrl,
      dropdownFieldName,
      dropdownFieldValue,
      index,
      option = {},
      isMultipleOption,
    } = params;

    let separator = index !== undefined ? "_" + index : "";

    if ((isMultipleOption && !name) || name === "") {
      error["name" + separator] =
        language[this.props.defaultLanguage].item_name_required;
    }

    //validate all fields
    if (option?.value === "integration" || option?.type === "integration") {
      if (!apiUrl || apiUrl === "") {
        error["apiUrl" + separator] =
          language[this.props.defaultLanguage].api_url_required;
      } else if (!urlValidation(apiUrl)) {
        error["apiUrl" + separator] =
          language[this.props.defaultLanguage].api_url_invalid;
      }

      if (!dropdownFieldName || dropdownFieldName === "") {
        error["dropdownFieldName" + separator] =
          language[this.props.defaultLanguage].field_name_required;
      }

      if (!dropdownFieldValue || dropdownFieldValue === "") {
        error["dropdownFieldValue" + separator] =
          language[this.props.defaultLanguage].field_value_required;
      }
    }
  }

  onSubmit = async () => {
    let { serviceData, defaultLanguage } = this.props;
    let { questionnaire = [] } = serviceData;

    if (!serviceData._id) {
      return toastMessage(
        "error",
        language[defaultLanguage].service_is_missing
      );
    }

    await this.validateForm();

    if (Object.keys(this.state.error).length === 0) {
      let user = await getStorage();

      this.setState({
        isSubmitting: true,
      });

      const {
        answerType,
        questionText,
        dataIndex,
        answerOptions,
        apiUrl,
        dropdownFieldName,
        dropdownFieldValue,
        responseKey,
        apiMethod,
        apiParams,
        ippisToken,
        required,
      } = this.state;

      let method = "PUT";

      let singleAnswer = {
        required,
      };

      let _answerOptions = answerOptions
        ? answerOptions.map((el, i) => {
            if (el && el.value !== "" && el.option) return el;
          })
        : [];

      if (
        answerType.value === "integration" ||
        answerType.type === "integration"
      ) {
        singleAnswer = {
          apiUrl,
          dropdownFieldName,
          dropdownFieldValue,
          responseKey,
          apiMethod,
          apiParams,
          ippisToken,
        };
      }

      console.log("singleAnswer", singleAnswer);

      //update
      if (dataIndex !== undefined) {
        questionnaire[dataIndex].questionText = questionText;
        questionnaire[dataIndex].answerType = answerType.type;
        questionnaire[dataIndex].singleAnswer = singleAnswer;
        // questionnaire[dataIndex].multiAnswer = multiAnswer;

        if (answerOptions) {
          // questionnaire[dataIndex].options = _answerOptions;
          questionnaire[dataIndex].multiAnswer = _answerOptions;
        }
        // if (multiAnswer && multiAnswer.length > 0) {
        //   questionnaire[dataIndex].multiAnswer = _answerOptions;
        // }
      } else {
        questionnaire.push({
          questionText,
          // options: _answerOptions,
          answerType: answerType.type,
          multiAnswer: _answerOptions,
          singleAnswer,
        });
      }

      const options = {
        method,
        url: `${process.env.REACT_APP_PSRP_BASE_API}/service`,
        data: {
          id: serviceData._id,
          questionnaire,
        },
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };

      axios(options)
        .then((data) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage(
            "success",
            language[this.props.defaultLanguage][
              method === "PUT"
                ? "update_questionnaire_success"
                : "add_questionnaire_success"
            ]
          );

          this.props.handleCloseModal();
          this.props.getData();
        })
        .catch((error) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage(
            "error",
            language[this.props.defaultLanguage][
              method === "PUT"
                ? "error_update_questionnaire"
                : "error_add_questionnaire"
            ]
          );
        });
    }
  };

  render() {
    console.log(this.state);
    const { question, answer_type, question_placeholder, select } =
      language[this.props.defaultLanguage];
    return (
      <div>
        <div className="card-body">
          <Input
            placeholder={question_placeholder}
            label={question}
            required
            value={this.state.questionText}
            onChange={(e) => this.onChangeText("questionText", e)}
            error={this.state.error.questionText}
          />
          <Select
            placeholder={select}
            options={common_input_types(this.props.defaultLanguage)}
            label={answer_type}
            required
            value={this.state.answerType}
            onChange={(e) => this.onChangeText("answerType", e)}
            error={this.state.error.answerType}
          />
          {(this.state.answerType.value === "multipleChoice" ||
            this.state.answerType.value === "checkbox") && (
            <MultipleChoice
              handleOptionsChanges={this.handleMultipleChoiceChanges.bind(this)}
              answerOptions={this.state?.answerOptions?.map((value, i) => {
                return {
                  field: "choice_" + i,
                  ...value,
                };
              })}
              error={this.state.error}
            />
          )}
          {this.state.answerType.value === "integration" && (
            <IntegrationForm
              onChangeInput={(field, e) => this.onChangeText(field, e)}
              apiUrl={this.state?.apiUrl}
              dropdownFieldName={this.state?.dropdownFieldName}
              dropdownFieldValue={this.state?.dropdownFieldValue}
              apiMethod={this.state?.apiMethod}
              responseKey={this.state?.responseKey}
              apiParams={this.state?.apiParams}
              error={this.state.error}
            />
          )}
          {this.state.answerType.value === "multipleAnswer" && (
            <MultipleAnswer
              handleOptionsChanges={this.handleMultipleAnswerChanges.bind(this)}
              multiAnswer={this.state.multiAnswer}
              isUpdate={this.props.dataIndex !== undefined}
            />
          )}
          <Checkbox
            label={language[this.props.defaultLanguage].required}
            checked={this.state.required}
            onCheck={() => this.handleCheckbox("required")}
          />
        </div>
        <div className="modal-footer gap-2">
          <Button
            text={language[this.props.defaultLanguage].cancel}
            onPress={this.props.handleCloseModal}
            className="btn-default"
          />
          <Button
            text={language[this.props.defaultLanguage].submit}
            onPress={this.onSubmit.bind(this)}
            isSubmitting={this.state.isSubmitting}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(NewQuestionnaire);
