const API_URL_IMAGE = `${process.env.REACT_APP_PSRP_URL}/userguide`;

export const data = {
  english: [
    {
      title: "Account Login",
      description:
        "Admin account or any other user account of the system must provide their username and password for authentication. This credentials are the same as your current IPPIS credentials",
      listActions: [
        {
          title: "Navigate to https://services.mifotra.gov.rw/admin-login",
        },
        {
          title: "Enter Email Address and password",
        },
        {
          title: "Press Login button",
        },
      ],
      imageUrl: `${API_URL_IMAGE}/admin-login_guide_kgfuqr.png`,
    },
    {
      title: "Admin - Create Access Roles",
      description:
        "An admin account create access roles for other accounts, this determines the information an account belongs will be able to access. To create access roles for other accounts",
      listActions: [
        {
          title: "Admin must be authenticated",
        },
        {
          title: "Sidebar menus: Click on Departments ",
        },
        {
          title: "Tabs: Click on tabs Access Roles",
        },
        {
          title: "Name Access Role",
        },
        {
          title:
            "On menus dropdown, select menus that you want the role to be assigned",
        },
        {
          title:
            "Each menu selected display the role actions: POST: Can Create, GET: can read, DELETE: can delete, UPDATE: can update",
        },
      ],
      imageUrl: `${API_URL_IMAGE}/access_roles-user_guide_azqhwm.png`,
    },
    {
      title: "Admin - Create Administration Account",
      description:
        "To create a new administration account, you need a valid IPPIS employee ID and first name of the administrator",
      listActions: [
        {
          title: "Enter a valid IPPIS employee ID and first name",
        },
        {
          title: "Click search button",
        },
        {
          title:
            "When information is valid, then the system will populate the information into the fields",
        },
        {
          title: "Check the information",
        },
        {
          title: "Click Submit",
        },
        {
          title:
            "User registered successfully, will receive a confirmation email message",
        },
      ],
      imageUrl: [
        `${API_URL_IMAGE}/create_account-user_guide_oc1qnm.png`,
        `${API_URL_IMAGE}/create_account_modal_form_qv0ob5.png`,
      ],
    },
    {
      title: "Create Institution Administration Account",
      description:
        "During the account creation process, specify if is outside ot the organization: this indicate that the account created will be used as an administrator of another institution",
      listActions: [
        {
          title:
            "Enter a valid IPPIS employee ID and first name of the administrator",
        },
        {
          title: "Click search button [icon here]",
        },
        {
          title:
            "When information is valid, then will populate the information into the fields",
        },
        {
          title: "Check the information and create user account password",
        },
        {
          title: "Enable Outside Organization",
        },
        {
          title: "Click Submit",
        },
      ],
      imageUrl: `${API_URL_IMAGE}/sub_approvers_-creation_wuqoek.png`,
    },
    {
      title: "Active and DisActivate an account",
      description:
        "User account can be activated and DisActivate by the administrator",
      listActions: [
        {
          title: "Side menus: Click Account menu",
        },
        {
          title:
            "Tabs: Click whether active account (list of all active accounts) or inactive accounts (list of all inactive accounts)",
        },
        {
          title:
            "Every account listed in the table has 3 dots at the end (if you are not seeing the dots just scroll horizontally) then click the dots will show the action menus ",
        },
        {
          title: "Click Edit button",
        },
        {
          title:
            "Enter first name and hit search button that it is appeared in the employee id field",
        },
        {
          title:
            "Form modal box: then status field select active if you are deactivating or select inactive if you are disActivating",
        },
        {
          title: "Click Submit",
        },
      ],
      imageUrl: `${API_URL_IMAGE}/active-inactive_account_ehaq44.png`,
    },
    {
      title: "Create Service",
      description:
        "To create a new service, an administrator account must be assigned to the  access role that is including a creation of service [SERVICE: POST], ",
      listActions: [
        {
          title: "Account login required",
        },
        {
          title: "Side menus: Click service > Individuals / Institutions",
        },
        {
          title: "Tabs: Click Services tab",
        },
        {
          title: "Click Add Service button",
        },
        {
          title: "Fill the Service form",
        },
        {
          title:
            "Select category > if category is missing from the list then go to service category tab and create new category and reopen modal dialog or refresh the page",
        },
        {
          title: "Click submit button",
        },
        {
          title:
            "service added successfully, system redirect to service page where to required documents, questionnaires and etc...",
        },
      ],
      imageUrl: `${API_URL_IMAGE}/New_service_bufzp7.png`,
    },
    {
      title: "Required Documents",
      description:
        "Continuity of service creation process, to add required documents must select a service ",
      listActions: [
        {
          title: "Click on service",
        },
        {
          title: "Click required documents tab",
        },
        {
          title: "Click add document button",
        },
        {
          title: "Fill the form",
        },
        {
          title: "Submit",
        },
      ],
      imageUrl: [
        `${API_URL_IMAGE}/create_document_isg0eq.png`,
        `${API_URL_IMAGE}/document_form_z4cfjo.png`,
      ],
    },
    {
      title: "Questionnaire",
      description:
        "Continuity of service creation process, to add questionnaire must select a service ",
      listActions: [
        {
          title: "Click on service",
        },
        {
          title: "Click questionnaire tab",
        },
        {
          title: "Click add questionnaire button",
        },
        {
          title: "Fill the form",
        },
        {
          title: "Submit",
        },
      ],
      imageUrl: [
        `${API_URL_IMAGE}/new_questionnaire_hpbbls.png`,
        `${API_URL_IMAGE}/questionnaire_form_axr4lt.png`,
      ],
    },
    {
      title: "SubApprovers",
      description:
        "Continuity of service creation process, to add sub approvers must select a service ",
      listActions: [
        {
          title: "Click on service",
        },
        {
          title: "Click subApprovers tab",
        },
        {
          title: "Click add sub-Approver button",
        },
        {
          title: "Fill the form",
        },
        {
          title: "Submit",
        },
      ],
      imageUrl: [
        `${API_URL_IMAGE}/create_sub_approvers_botwe3.png`,
        `${API_URL_IMAGE}/sub_approvers_form_kc1qjg.png`,
      ],
    },
    {
      title: "Approvers",
      description:
        "Continuity of service creation process, to add approver must select a service ",
      listActions: [
        {
          title: "Click on service",
        },
        {
          title: "Click approver tab",
        },
        {
          title: "Click add approver button",
        },
        {
          title: "Fill the form",
        },
        {
          title: "Submit",
        },
      ],
      imageUrl: [
        `${API_URL_IMAGE}/approveres_rqsfpj.png`,
        `${API_URL_IMAGE}/create_approvers_y2whrt.png`,
      ],
    },
  ],
};
